import { createContext} from "react";

export const IsPreparingContext = createContext(true)
export const IsUnworkingContext = createContext(true)
export const IsRoundContext = createContext(true)
export const isPostContext = createContext(true)
export const StopwatchValueContext = createContext(true)
export const TotalSumContext = createContext(true)
export const MaxStopwatchValueContext = createContext(true)
export const OldCoefsListContext = createContext(true)
export const MessageAlertContext = createContext(true)
export const fetchDataContext = createContext(true)
