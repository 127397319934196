import React, { useEffect, useState } from 'react'
import classes from '../rightsection.module.css'
import OldCoefsRow from './OldCoefsRow'

const PreviousCoefs = () => {
    const [isPanel, setPanel] = useState(false)
    const [isRed, setIsRed] = useState('')
    const [panekBlock, setBlock] = useState('none')
    const [padding, setPadding] = useState('3px 7px')
    const [paddingUpperField, setPaddingUpperField] = useState('0')
    const [margin, setMargin] = useState('none')
    const [right, setRight] = useState('1%')
    const [width, setWidth] = useState('')
    
    const marginBottom = '0px'

    function ChosePanel(){
      setPanel(!isPanel)
    }
    
    useEffect(() =>{

    })
    
    useEffect(() =>{
      if (isPanel){
        setWidth('97%')
        setIsRed('_red')
        setBlock('block')
        setPadding('0')
        setPaddingUpperField('5px 11px')
        setMargin('5px')
        setRight('1%')

      }else{
        setWidth('')
        setRight('1%')
        setMargin('0')
        setIsRed('')
        setBlock('none')
        setPaddingUpperField('0')
        setPadding('3px 7px')

      }
    },[isPanel])

  return (
    <div className="previous_coefs" style={{position: "relative"}}> 
                
                <div className={`${classes.old_coefs_list} ${classes.basic_old_coef_div}`}> 
                    <OldCoefsRow />
  
                </div>
                <div className={classes.make_darker}></div>
                <div className={classes.more_old_coefs} id="not-changed" style={{padding: padding, right: right, width: width, top:'25%'}}>
                    <div className={classes.upper_field_old_coefs} onClick={ChosePanel} style={{display:'flex', flexDirection:'row', justifyContent:'space-between', padding: paddingUpperField,
                  margin: margin }}>
                        <div className="old_rounds_history" style={{display: panekBlock}}>TURLARIN TARİHİ</div>
                        <div className={classes.images_old_coefs}>
                            <img className = {classes.more_coefs} src={`./media/history${isRed}.svg`} alt=""/>
                            <img className = {classes.heart_more_coefs} src={`./media/heart${isRed}.svg`} style={{height:'10px', width:'10px'}} alt=""/>
                        </div>
                    </div>
                    <div className={classes.lower_field_old_coefs} style={{display: panekBlock}}>
                      {isPanel && 
                          <div className={`${"old_coefs_list"} ${classes.lower_old_coef_list}`} id="lower_list"> 
                            <OldCoefsRow   />
                          </div>
                      }
                        
                    </div>
                    
                    
                </div>
            </div>
  )
}

export default PreviousCoefs