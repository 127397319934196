import React,{useState, useEffect, useContext} from 'react'
import WinCoef from './winCoef'
import classes from './FakeBidders.module.css'
import { IsPreparingContext } from '../../../context/allContext'

const Row = ({ coef}) => {
    const [backColor, setBackColor] = useState('none')
    const [border, setBorder] = useState('none')
    const [winTry, setWinTry] = useState('')
    
    const {isPreparing, setIsPreparing} = useContext(IsPreparingContext)

  useEffect(() => {
    if(isPreparing){

        setBackColor('none')
        setBorder('none')

    }
  },[isPreparing])

  return (
     <div   className={`${classes.row}`} style={{backgroundColor: backColor, border: border}}> 
          <div className={[classes.cell, classes.user_field].join(' ')}>
            <img src={coef[1]} className={classes.img_bidder}/>
            {coef[2]}
          </div>
          <div className={[classes.cell, classes.coef_win_cell].join(' ')}>
            <div>{coef[0]}
            </div>
            <WinCoef  setBorderRow={setBorder} setBackColorRow={setBackColor} setTry={setWinTry} bid={coef[0]}/>
          </div>
          <div className={[classes.cell, classes.win_cell].join(' ')}>{winTry}</div>
          
    </div>
  )
}

export default Row