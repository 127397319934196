import React, { useContext, useEffect, useState } from 'react'
import { IsPreparingContext } from '../../../context/allContext'
import classes from './FakeBidders.module.css'
import Row from './Row'

const TableBidders = () => {
  const {isPreparing, setIsPreparing} = useContext(IsPreparingContext)
  const [dataBid, setData] = useState([])
  const [newBidders, getBidders] = useState([])
  
  


  function PlaceNewBidders  () {
    fetch('/load_new_bidders', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',}})
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok', response);
          }
          return response.json();
        })
        .then((result) => 
        { 
          const res = result['info']
          setData(res)
        
       
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
  }

  useEffect(() => {
    if(isPreparing){

        setData([])
        setTimeout(PlaceNewBidders, 1500)

    }
  },[isPreparing])
  


  
  return (
      
    <div className={classes.table}>
    {dataBid.map((coef, index) => 
            <Row key={index} coef={coef} /> )} 
    </div>
  )
}

export default TableBidders