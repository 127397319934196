import React ,{useState}from 'react'
import FakeBidders from './fake-bidders/FakeBidders'
import RightSection from './right_section/RightSection'
import { StopwatchValueContext } from '../../context/allContext'

const Container = ({oldCoefs, fakeCoefs, fakeBiddersAmount, bidders_info}) => {
  const [stopwatchValue, getStopwatchValue] = useState('1.00')
  return (
  <StopwatchValueContext.Provider value={{stopwatchValue, getStopwatchValue}}>
    <div className='container'>
      <FakeBidders  AmountBidders = {fakeBiddersAmount} fake_bidders_info = {bidders_info}  />
      <RightSection oldCoefs={oldCoefs}  fakeCoefs={fakeCoefs} />
    </div>
  </StopwatchValueContext.Provider>
  )
}

export default Container