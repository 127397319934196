import React, {useState } from 'react'
import classes from './axis.module.css'
import { renderIntoDocument } from 'react-dom/test-utils'

const AxisXCoordination = () => {

  const [newElements, addEle] = useState([])

  const handleAnimation = () => {
    addEle([])
    setTimeout(function(){
      addEle([1])
  },1000)}

  const newEle = <div key={newElements.length} className={classes.x_dots}  style={{right:'-98%', position:'absolute', width:'100%', paddingLeft:'0'}}>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
  <div className={`${classes.dot} ${classes.dot_left}`}></div>
</div>

  const newAnimation = () =>{
    addEle([])
    setTimeout(function(){
        addEle([1])
    },2000)}


  return (
    <div className={classes.x_axis} id="x_axis">
                    <div className={classes.coordinate_x}></div>
                    {newElements.map((ele) =>(
                      newEle
                      ))}
                    <div className={classes.x_dots}   onAnimationStart={handleAnimation} onAnimationIteration={ newAnimation}>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                        <div className={`${classes.dot} ${classes.dot_left}`}></div>
                    </div>
                </div>
  )
}

export default AxisXCoordination