import React, { useEffect, useState } from 'react'
import cl from './make_bids.module.css'

const AutoBid = ({isAutoPanel, setAuto, setCash, setCashValue, isAuto, isAutoCash}) => {
  
  const [InputAuto, AutoFunc] = useState({checked:false, disabled:false})
  const [InputCash, CashFunc] = useState({checked:false, disabled:false})
  const[CashValue, changeCashValue] = useState('1.10')
  const [disabled, setDisabled] = useState(false)
  
  function handlerChangeCash(e){
    changeCashValue(e.target.value)
  }

  useEffect(() =>{
    if (InputAuto.checked){
      CashFunc(preValue => {return {checked:preValue.checked, disabled:true}})
      setAuto(true)
    }else{
      CashFunc(preValue => {return {checked:preValue.checked, disabled:false}})
      setAuto(false)
    }
  }, [InputAuto.checked])

  useEffect(() => {
    if (InputCash.checked){
      setCash(true)
      setCashValue(CashValue)
      setDisabled(true)
    }else{
      setDisabled(false)
      setCash(false)
      setCashValue('')
    }
  }, [InputCash.checked])
  
  function CheckBoxCash(){
    if(!InputCash.disabled){
      CashFunc(preValue => {return {checked:!preValue.checked, disabled:preValue.disabled}})
    }
    
  }

  function CheckBoxAuto(){
    AutoFunc(preValue => {
      return {checked: !preValue.checked, disabled: preValue.disabled}})
  }

  function unCheckedAuto(){
    AutoFunc(preValue => {
      return {checked: false, disabled: preValue.disabled}})
  }

  useEffect(() => {
    if (!isAuto){
      unCheckedAuto()
    }
  }, [isAuto])

  return (
    <div className={cl.oto} style={{display: isAutoPanel}}>
                        <div className={cl.auto_bid}>
                            <p>Oto Bahis</p>
                            <label className={cl.switch}>
                                <input readOnly={true} type="checkbox" className="auto_bid_checkbox checkbox" checked={InputAuto.checked} onClick={CheckBoxAuto} />
                                <span className={cl.slider} ></span>
                            </label>
                        </div>
                        <div className={cl.auto_cash}>
                            <p>Oto Para Çekm</p>
                            <label className={cl.switch}>
                                <input readOnly={true}  type="checkbox" className="auto_cash_checkbox checkbox" checked={InputCash.checked} onClick={CheckBoxCash}  />
                                <span className={cl.slider} ></span>
                            </label>
                            <input  type="number" step="0.01" name="float_input cash_input" disabled={disabled}  className="cash_input" value={CashValue} onChange={handlerChangeCash}/>
                            
                        </div>
    </div>
  )
}

export default AutoBid