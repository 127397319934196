import React, {useState, useContext, useEffect } from 'react'
import classes from './FakeBidders.module.css'
import { MaxStopwatchValueContext, StopwatchValueContext, isPostContext, IsPreparingContext } from '../../../context/allContext'

const WinCoef = ({number,  setBorderRow, setBackColorRow, setTry, bid}) => {

    const [coef, setCoef] = useState(false)
    const [coefNext, setCoefNext] = useState(false)
    const {maxCoef, changeMaxCoef} = useContext(MaxStopwatchValueContext)
    const {stopwatchValue, getStopwatchValue} = useContext(StopwatchValueContext)
    const {isPreparing, setIsPreparing} = useContext(IsPreparingContext)
    const [IsWin, setIsWin] = useState(false)
    const [display, setDisplay] = useState('none')

    function generateRandomFloat(min, max) {
        const coefing = Number((Math.random() * (max - min) + min).toFixed(2))
        return coefing;
    }
    
    useEffect(() =>{
        if(IsWin && coefNext){
            if(Number(stopwatchValue) == coefNext){
                setBackColorRow('#133407')
                setBorderRow('1px solid #3d621e')
                setCoef(`${coefNext}x`)
                setDisplay('block')
                setTry((Number(coefNext)*Number(bid)).toFixed(2))
            }    
        }
            
    },[stopwatchValue])
    
    useEffect(() => {
        if (maxCoef) {
          const coefing = generateRandomFloat(1.00, Number(maxCoef));
          setCoefNext(coefing)

        }
      }, [maxCoef]);
    
    useEffect(() => {
        if (isPreparing) {

          setCoefNext(false);
          setCoef(false);
          setDisplay('none')
          setTry('')
        }
      }, [isPreparing]);

    function isWinner(){
        if ( Math.random() > .5 ){
            setIsWin(true)
        } else {
            setIsWin(false)  
        }
    }

    useEffect(() =>{
        isWinner()
    },[])

  return (
    
    <div className={`${classes.cell} ${classes.win_coef_fake} ${Number(coefNext) < 2
        ? classes.lessThan2
        : Number(coefNext) >= 2 && Number(coefNext) <= 10
        ? classes.between2and10
        : classes.moreThan10}`} key={number}  style={{display:display}}>{coef}</div>
  )
}

export default WinCoef