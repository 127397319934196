import React, { cloneElement, useState } from 'react'
import classes from './axis.module.css'

const AxisCoordinates = () => {
  const [newElements, addEle] = useState([])

  const handleAnimation = () => {
    addEle([])
    setTimeout(function(){
    addEle([1])
  },2000)
 
  };

  const newAnimation = () =>{
    addEle([])
    setTimeout(function(){
        addEle([1])
    },2000)}
    
  const  newEle = 
      <div className={classes.y_dots} style={{top:'-97%', display:'flex', position:'absolute', left:'45%'}} key={newElements.length}>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    <div className={`${classes.dot} ${classes.dot_right}`}></div>
    </div>
  return (
    <div className={classes.y_axis} id="y_axis">
                    <div className={classes.coordinate_y}></div>
                    {newElements.map((ele) =>(
                      newEle
                      ))}
                    <div className={classes.y_dots} id="y_dots" onAnimationStart={(e) => handleAnimation(e)} onAnimationIteration={(e) => newAnimation(e)}>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                        <div className={`${classes.dot} ${classes.dot_right}`}></div>
                    </div>
                    
    </div>
                
  )
}

export default AxisCoordinates