import React, { useState, useContext, useEffect } from 'react'
import TimeBar from './TimeBar'
import classes from './UI.module.css'
import { IsPreparingContext, IsUnworkingContext } from '../../../../../context/allContext'


const StartRound = () => {
    const {isPreparing, setIsPreparing } = useContext(IsPreparingContext)
    const {IsUnworking, setIsUnworking} = useContext(IsUnworkingContext)
    const [displayDiv, setDisplay] = useState('block')

    useEffect(() => {
        if (isPreparing) {
          setDisplay('block')
        }else{
            setDisplay('none')
        }
      
    
      }, [isPreparing]);
   

  return (
    <div className={classes.start_round} style={{display: displayDiv}}>
                    <div className={classes.propeller}></div>
                    <h3 style={{fontWeight: "400px"}}>SONRAKI TUR BEKLENIYOR</h3>
                    <TimeBar/>
    </div>
  )
}

export default StartRound