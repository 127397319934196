import React, { useEffect, useState, useContext } from 'react'
import classes from './GameSection.module.css'
import { IsRoundContext, StopwatchValueContext } from '../../../../context/allContext'

const Glow = () => {
    const [opacity, setOpacity] = useState('0')
    const [color, setColor] = useState('rgb(39 110 192 / 83%)')

    const {isRound, setIsRound} = useContext(IsRoundContext)
    const {stopwatchValue, getStopwatchValue} = useContext(StopwatchValueContext)

    useEffect(() =>{
        if(isRound){
            setOpacity('80%')
        }else{
            setOpacity('0')
            setColor('rgb(39 110 192 / 83%)')
        }
    },[isRound])
    
    useEffect(()=>{
        if (Number(stopwatchValue)==2.00){
            setColor('#54288a')
        } else if(Number(stopwatchValue) ==10.00){
            setColor('#C628a2')
        }
    },[stopwatchValue])



  return (
    <div className={classes.glow} style={{opacity: opacity, backgroundColor:color}}></div>
  )
}

export default Glow