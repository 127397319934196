import React, { useEffect, useState, useContext } from 'react'
import { IsUnworkingContext, IsRoundContext, IsPreparingContext} from '../../../../../context/allContext'
import classes from './UI.module.css'

const TimeBar = () => {
  const {IsUnworking, setIsUnworking} = useContext(IsUnworkingContext)
  const {isPreparing, setIsPreparing } = useContext(IsPreparingContext)
  const {isRound, setIsRound} = useContext(IsRoundContext)
  function StartUnWorking() {
    setIsUnworking(true)
    setIsPreparing(false)
  }
  
  useEffect(() => {
    if (IsUnworking){
      setTimeout(() => {
        setIsUnworking(false)
        setIsRound(true)}, 2000)
    }
  },[IsUnworking])
  
  return (
    <div className={classes.time_bar}>
                        <div className={classes.filler} onAnimationIteration={StartUnWorking}></div>
    </div>
  )
}

export default TimeBar