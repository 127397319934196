import React, { useState } from 'react'
import TypeBidding from './TypeBidding'
import AutoBid from './AutoBid'
import cl from './make_bids.module.css'
import MakeBidsButtonArea from './MakeBidsButtonArea'



const Bid = ({bid, bidding, changeBidding}) => {
  const [border, setBorder] = useState('none')
  const [isAutoPanel, makeAutoPanel] = useState('none')
  const [isAuto, setAuto] = useState(false)
  const [isAutoCash, setAutoCash] = useState(false)
  const [cashValue, setCashValue] = useState('')

  return (
    <div className={cl.bid}  style={{border: border}}>  
                    <TypeBidding makeAutoPanel = {makeAutoPanel} setAuto = {setAuto} setCash = {setAutoCash} isAuto={isAuto} isCash={isAutoCash}/>

                    <MakeBidsButtonArea changeBorder={setBorder } isAuto={isAuto} isCash={isAutoCash} setAuto={setAuto}
                     cashValue={cashValue} setCashValue={setCashValue} bid={bid} bidding={bidding} changeBidding={changeBidding} />
                     
                    <div className="divider" style={{display: "none"}}></div>

                    <AutoBid isAutoPanel = {isAutoPanel} setAuto={setAuto} setCash={setAutoCash} setCashValue={setCashValue} isAuto={isAuto} isCash={isAutoCash}/>
    </div>
  )
}

export default Bid