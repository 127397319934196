import React, { useContext, useEffect } from 'react'
import classes from '../rightsection.module.css'
import { OldCoefsListContext } from '../../../../context/allContext'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const OldCoefsRow = () => {
    const {oldCoefsList, setOldCoefsList} = useContext(OldCoefsListContext)
    useEffect(()=>{

    },[oldCoefsList])

  return (
    <TransitionGroup>
        <div className={`${"old_coefs_list"} ${classes.lower_old_coef_list}`} > 
                          {oldCoefsList && oldCoefsList.map((coef) => 
                          <CSSTransition 
                          in= {true} 
                          key={coef.id}
                           classNames={'old_coef'} 
                           timeout={{enter: 2000}}>
                              <div 
                                className={`${'old_coef'} ${coef.coef < 2
                                    ? classes.lessThan2
                                    : coef.coef >= 2 && coef.coef <= 10
                                    ? classes.between2and10
                                    : classes.moreThan10}`}> 
                                {Number(coef.coef).toFixed(2)}x 
                              </div>
                          </CSSTransition>
                          
                          )
                          } 
      </div>
    </TransitionGroup>
    
  )
}

export default OldCoefsRow