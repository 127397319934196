import './App.css';
import Header from './components/header/Header'
import {fetchDataContext, OldCoefsListContext, IsPreparingContext, IsRoundContext, IsUnworkingContext, isPostContext, TotalSumContext, MaxStopwatchValue, MaxStopwatchValueContext, MessageAlertContext } from './context/allContext';

import React,{useState, useEffect } from 'react';
import MsgAlert from './components/messages-alert/msgAlert';
import Container from './components/container/container';

function App() {
    const [fake_coefs, setCoefs ]=useState([])
    const [fake_bidders_amount, getAmountBidders ] = useState([])
    const [fake_bidders_info, getFakeBiddersInfo] = useState([])
    
    const [fetching, setFetch] = useState('')
    const [MessagesAlert, setMesAlert] = useState({is:false, 1:{coef:false, money:false}, 2:{coef:false, money:false}})

    const[oldCoefsList, setOldCoefsList] = useState(false)
    const  [isPreparing, setIsPreparing] = useState(true)
    const [isRound, setIsRound] = useState(false)
    const [isPost, setIsPost] = useState(false)
    const [IsUnworking, setIsUnworking] = useState(false)
    const [TotalSum, setTotalSum] = useState(false)
    const [maxCoef, changeMaxCoef] = useState('')
    
    useEffect(() => {
      getCoefs()
    },[])

    async function getCoefs () {
        let response = await fetch('/game');
        let data = await response.json();
        setCoefs(JSON.parse(data['fake_coefs']));
        setTotalSum(data['amount']);
        getAmountBidders(data['all_info']);
        getFakeBiddersInfo((data['bidders_info']))
        setOldCoefsList(JSON.parse(data['old_coefs']))
        setFetch(data['csrf'])

      
    }
    
  return (
    <fetchDataContext.Provider value={{fetching, setFetch}}>
        <MessageAlertContext.Provider value={{MessagesAlert, setMesAlert}}>
      <OldCoefsListContext.Provider value={{oldCoefsList, setOldCoefsList}}>
        <MaxStopwatchValueContext.Provider value={{maxCoef, changeMaxCoef}}>
          <TotalSumContext.Provider value={{TotalSum, setTotalSum}}>
            <isPostContext.Provider value={{isPost, setIsPost}}>
              <IsUnworkingContext.Provider value = {{IsUnworking, setIsUnworking}}>
                <IsRoundContext.Provider value={{isRound, setIsRound}}>
                  <IsPreparingContext.Provider value={{isPreparing, setIsPreparing}}>
                    
                    <div className="App">
                      
                      <div className='messages_alert'>
                        <MsgAlert className='message-one' number='1'/>
                        <MsgAlert  className='message-two' number='2'/>
                      </div>
                      
                      <Header/>
                      <Container fakeCoefs = {fake_coefs} fakeBiddersAmount = {fake_bidders_amount} bidders_info = {fake_bidders_info}/>
                      
                    </div>
                    </IsPreparingContext.Provider>
                </IsRoundContext.Provider>
              </IsUnworkingContext.Provider>
            </isPostContext.Provider>
          </TotalSumContext.Provider>
        </MaxStopwatchValueContext.Provider>
      </OldCoefsListContext.Provider>
    
    </MessageAlertContext.Provider>
    </fetchDataContext.Provider>
    
    
  );
}

export default App;
