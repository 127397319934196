import React, { useState } from 'react'
import cl from './make_bids.module.css'

const TypeBidding = ({makeAutoPanel, setAuto, setCash, isAuto,isCash}) => {
  const [activeOto, makeOtoActive] = useState('')
  const [activeBid, makeBIdActive] = useState(cl.type_bidding_active)

  function BahisClick(){
    if(isAuto){
      setAuto(false)
    }
    if (isCash){
      setCash(false)
    }

    makeBIdActive(cl.type_bidding_active)
    makeOtoActive('')
    makeAutoPanel('none')
  }

  function OtoClick(){
    makeBIdActive('')
    makeOtoActive(cl.type_bidding_active)
    makeAutoPanel('grid')
  }
    
  return (
    <div className={[cl.chose_type_bidding, cl.chose_bids].join(' ')}>
                        <a href="#" className={[ cl.type_bidding, activeBid, cl.all].join(' ')} onClick={BahisClick}> <span>Bahis</span></a>
                        <a href="#" className={[ cl.type_bidding, cl.my, activeOto].join(' ')} onClick={OtoClick} ><span>Oto</span></a>   
    </div>
  )
}

export default TypeBidding